import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRespondentAnswerById,
  updateAnswerComment
} from './respondentAnswersSlice';
import { useDebouncedValue } from '@mantine/hooks';
import { logicValidate } from './logicSlice';
import { IconMail, IconPhone } from '@tabler/icons-react';
import { selectQuestionById } from './questionSlice';

const validate = (ans, pattern) => (!ans || pattern.test(ans))

export function CorrectAnswerIndicator () {
  return (
    <div style={{ color: 'green', fontSize: '30px', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: '&#10004' }} />
  )
}

export function useRespondentAnswer (questionId, hideFromLogic) {
  const dispatch = useDispatch()
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const [answer, setAnswer] = useState(respondentAnswer?.answerId ?? null)
  const [debounced] = useDebouncedValue(answer, 500)

  useEffect(() => {
    if (hideFromLogic && answer) {
      setAnswer(null)
    }
  }, [hideFromLogic, answer, setAnswer])

  useEffect(() => {
    if ((debounced === answer) && (answer !== respondentAnswer?.answerId)) {
      console.debug('Answer id changed.', respondentAnswer?.answerId, answer, questionId)
      dispatch(logicValidate({ id: questionId, isAdditional: false, isSkip: false, answerId: answer, pageId: respondentAnswer.pageId, assessmentId: respondentAnswer.assessmentId }))
    }
  }, [respondentAnswer?.answerId, respondentAnswer?.pageId, answer, questionId, debounced, respondentAnswer?.assessmentId, dispatch])

  return [answer, setAnswer]
}

export function useMultipleRespondentAnswer (questionId, hideFromLogic) {
  const dispatch = useDispatch()
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const [answers, setAnswers] = useState(respondentAnswer?.answerIds ?? [])
  const [debounced] = useDebouncedValue(answers, 1200)

  useEffect(() => {
    if (hideFromLogic && answers.length) {
      setAnswers([])
    }
  }, [hideFromLogic, answers, setAnswers])

  useEffect(() => {
    if ((debounced === answers) && (answers !== respondentAnswer?.answerIds)) {
      console.debug('Answer ids changed.', respondentAnswer?.answerIds, answers, questionId)
      dispatch(logicValidate({ id: questionId, isAdditional: false, isSkip: false, isMultiple: true, answerIds: answers, pageId: respondentAnswer.pageId, assessmentId: respondentAnswer.assessmentId }))
    }
  }, [respondentAnswer?.answerIds, respondentAnswer?.pageId, answers, questionId, debounced, respondentAnswer?.assessmentId, dispatch])

  return [answers, setAnswers]
}

export function useAdditionalRespondentAnswer (questionId, hideFromLogic) {
  const dispatch = useDispatch()
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const [answer, setAnswer] = useState(respondentAnswer?.additionalAnswer ?? '')
  const [debounced] = useDebouncedValue(answer, 500)
  const validation = useOpenEndedValidation(questionId, debounced)

  const answerValid = validation.isValid

  useEffect(() => {
    if (hideFromLogic && answer) {
      setAnswer(null)
    }
  }, [hideFromLogic, answer, setAnswer])

  useEffect(() => {
    if ((debounced === answer) && (answer !== respondentAnswer?.additionalAnswer) && answerValid) {
      console.debug('Additional answer changed - starting timeout.', respondentAnswer?.additionalAnswer, answer, questionId)
      const timeoutId = window.setTimeout(() => {
        dispatch(logicValidate({ id: questionId, isAdditional: true, isSkip: false, additionalAnswer: answer, pageId: respondentAnswer.pageId, assessmentId: respondentAnswer.assessmentId }))
      }, 1000)

      return () => window.clearTimeout(timeoutId)
    }
  }, [respondentAnswer?.additionalAnswer, respondentAnswer?.pageId, answer, questionId, debounced, respondentAnswer?.assessmentId, dispatch, answerValid])

  return [answer, setAnswer, validation]
}

export function useRespondentAnswerAdditionalComment (questionId) {
  const dispatch = useDispatch()
  const respondentAnswer = useSelector(state => selectRespondentAnswerById(state, questionId))
  const [additionalComment, setAdditionalComment] = useState(respondentAnswer?.additionalComment ?? '')
  const [debounced] = useDebouncedValue(additionalComment, 1000)

  useEffect(() => {
    if ((debounced === additionalComment) && (additionalComment !== respondentAnswer?.additionalComment)) {
      console.debug('Additional comment changed.', respondentAnswer?.additionalComment, additionalComment, questionId)
      dispatch(updateAnswerComment({ id: questionId, additionalComment: additionalComment }))
    }
  }, [respondentAnswer?.additionalComment, additionalComment, questionId, debounced, dispatch])

  return [additionalComment, setAdditionalComment]
}

// this hook matches `validateOpenEndedAnswer` function in `src/EntityManager/Build/AssessmentAssignmentManager.php`. Update both if updating this file
export function useOpenEndedValidation (questionId, answer) {
  const question = useSelector(state => selectQuestionById(state, questionId))
  switch (question.validationType) {
    case 'Email':
      return {
        isValid: validate(answer, /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/),
        error: 'Please enter a valid email address.',
        placeholder: 'Enter email address',
        icon: <IconMail />
      }
    case 'Phone Number':
      return {
        isValid: validate(answer, /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/), // found this phone number regex on SO. can be improved
        error: 'Please enter a valid phone number.',
        placeholder: 'Enter phone number',
        icon: <IconPhone />
      }
    default:
      return {
        isValid: true,
        message: null,
        icon: null,
        placeholder: 'Enter response'
      }
  }
}

export function getExpertiseLabelFromIndex (index) {
  switch (index) {
    case 0: {
      return 'None'
    }
    case 4: {
      return 'Expert'
    }
    default: {
      return <br />
    }
  }
}

export function getAdequacyLabelFromIndex (index) {
  switch (index) {
    case 0: {
      return 'Poor'
    }
    case 2: {
      return 'Adequate'
    }
    case 4: {
      return 'Impressive'
    }
    default: {
      return <br />
    }
  }
}

export function getSatisfactoryLabelFromIndex (index) {
  switch (index) {
    case 0: {
      return 'Poor'
    }
    case 1: {
      return 'Fair'
    }
    case 2: {
      return 'Satisfactory'
    }
    case 3: {
      return 'Good'
    }
    case 4: {
      return 'Excellent'
    }
    default: {
      return <br />
    }
  }
}
