/* eslint react/prop-types: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
import { ActionIcon, Box, Button, Container, Flex, Grid, Group, Indicator, LoadingOverlay, ScrollArea, Stack, Switch, TagsInput, Text, ThemeIcon, Tooltip } from '@mantine/core'
import { useWindowEvent } from '@mantine/hooks'
import { RichTextEditor } from '@mantine/tiptap'
import { IconChevronDown, IconEdit, IconPin, IconPinnedFilled, IconSend, IconTag, IconTrash, IconX } from '@tabler/icons-react'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import * as DOMPurify from 'dompurify'
import $ from 'jquery'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { getComments, postComment } from '../../../js/api/applicant_repository'
import {
  addTag,
  deleteComment,
  listApplicantTags,
  removeTag,
  updateComment
} from '../../../js/api/comment_repository'
import PermissionUtil from '../../../js/util/PermissionUtil'
import Dropdown from '../../core/Dropdown'
import styles from './Discussion.module.scss'
import Content from '../../layout/Content'
dayjs.extend(duration)

export default function Discussion ({ applicantId, user }) {
  const [comments, setComments] = useState([])
  const [tags, setTags] = useState([])
  const [focusId, setFocusId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [autoPin, setAutoPin] = useState(true)
  const [filterUnpinned, setFilterUnpinned] = useState(false)
  const [editId, setEditId] = useState(null)
  const [currentText, setCurrentText] = useState(null)
  const findComment = (id) => comments.find(comment => comment.id === id)

  const editor = useEditor({
    content: currentText,
    autofocus: 'end',
    extensions: [
      StarterKit
    ],
    editorProps: {
      attributes: {
        class: styles.commentWriteArea
      },
      handleKeyDown: (editor, ev) => {
        if (ev.key === 'Enter' && !ev.shiftKey) {
          if (editor.dom.innerText.trim().length > 0) post(editor.dom.innerHTML)
          return true
        }
      }
    }
  }, [currentText, autoPin, filterUnpinned])

  useEffect(() => {
    $(document).on('appcard:open', () => editor?.view.dom.focus())
  }, [editor])

  useEffect(() => {
    fetch()
  }, [])

  useEffect(() => {
    if (editId === null) {
      setCurrentText(null)
      return
    }
    const comment = findComment(editId)
    setCurrentText(comment.content)
    setFocusId(editId)
  }, [editId])

  useEffect(() => {
    refresh()
    focus()
  }, [comments])

  useEffect(() => {
    if (editor) setCurrentText(editor.getHTML())
  }, [autoPin])

  useEffect(() => {
    focus()
  }, [focusId])

  useWindowEvent('applicant:updated', () => fetch())

  const fetch = (focusTo = 'end') => {
    setLoading(true)
    if (applicantId !== undefined) {
      getComments(applicantId).then(response => {
        if (focusTo === 'end') focusTo = _.last(response)?.id ?? null
        setComments(response)
        setFocusId(focusTo)
        listApplicantTags(applicantId)
          .then(response => setTags(response.items))
          .finally(() => setLoading(false))
      })
    }
  }

  const refresh = () => {
    editor?.commands.clearContent()
    focus()
  }

  const focus = () => {
    if (focusId) {
      document.querySelector(`[data-id="${focusId}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
      setFocusId(null)
    }
  }

  const post = (newText = '') => {
    if (newText.length === 0) newText = editor.getText().length > 0 ? editor.getHTML() : ''
    if (newText.length > 0) {
      setLoading(true)
      if (editId === null) {
        postComment(applicantId, { content: newText, pinned: autoPin ? 1 : 0 }).then(response => {
          fetch(response)
          $(document).trigger('discussion:updatecomment')
        })
      } else {
        update({ id: editId, content: newText })
      }
      setEditId(null)
    }
  }

  const update = ({ id, content, pinned }) => {
    setLoading(true)
    const newData = {}
    if (content !== undefined) newData.content = content
    if (pinned !== undefined) newData.pinned = pinned ? 1 : 0
    updateComment(id, newData).then(response => {
      fetch(response)
      $(document).trigger('discussion:updatecomment')
    })
  }

  const remove = (id) => {
    if (confirm('Are you sure you want to delete this comment?')) {
      setComments(prev => {
        const newComments = [...prev]
        return _.remove(newComments, comment => {
          return comment.id !== id
        })
      })
      deleteComment(id).then(() => {
        $(document).trigger('discussion:deletecomment')
      })
    }
  }

  return (
    <Content>
      <Flex direction='column' align='stretch'>
        <Box mih='2rem' pos='relative'>
        <LoadingOverlay visible={loading} loaderProps={{ type: 'dots' }} />
        <ScrollArea.Autosize
          mah='25rem'
          pos='relative'
          p='xs'
          >
          <Stack gap={0}>
            {comments.map((comment, index) => {
              const isBot = comment.account === undefined
              const authorName = comment.account ? `${comment.account.first_name} ${comment.account.last_name}` : 'HS Bot'
              const isUserComment = user?.email === comment.account?.email
              const hasTagPermissions = isUserComment || PermissionUtil.isAdmin(user?.roles)
              const date = dayjs(comment.created_at)
              let isSubComment = false
              if (!isBot && comments[index - 1]?.account) {
                const prevComment = comments[index - 1]
                if (dayjs(prevComment.created_at).fromNow() === date.fromNow() ||
                dayjs.duration(date.diff(prevComment.created_at)).asMinutes() < 5) isSubComment = true
              }
              return ((filterUnpinned && comment.pinned) || !filterUnpinned) && (
                <Grid
                  gutter={0}
                  key={`comment-${comment.id}`}
                  data-id={comment.id}
                  pb={0}
                  pt={isSubComment ? 0 : 'sm'}
                  className={[styles.comment, (editId === comment.id && styles.editing)]}
                  >
                  <Grid.Col
                    span={1}
                    className={[styles.commentAvatar]}
                    >
                    {!isSubComment && <ThemeIcon radius='xl' p='sm' color={isBot ? 'gray' : 'blue'} >{isBot ? 'HS' : authorName.match(/\b(\w)/g).join('')}</ThemeIcon>}
                  </Grid.Col>
                  <Grid.Col span={9}>
                    <Group wrap='nowrap' grow>
                      <Stack gap='xs'>
                        <Group gap='xs' wrap='nowrap' align='center'>
                          {!isSubComment && <Text fz='md' fw='bold'>{authorName}</Text>}
                          <Box className={isSubComment && styles.subDataHidden}>
                            <Tooltip
                              label={isSubComment ? `at ${date.format('h:mm a')}` : date.format('MMMM Do YYYY, h:mma')}
                              >
                              <Text fz='xs' fs='italic'>
                                {isSubComment ? date.format('MMMM Do YYYY') : date.fromNow()}
                              </Text>
                            </Tooltip>
                          </Box>
                        </Group>
                        <Flex direction='column'>
                          <Box className={[styles.commentContent]} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(comment.content) }} />
                          <CommentTags comment={comment} tags={tags} setTags={(tags) => setTags(tags)} hasPermission={hasTagPermissions} />
                        </Flex>
                      </Stack>
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Group
                      pr='xs'
                      wrap='nowrap'
                      gap='xs'
                      className={[styles.commentActions, (editId === comment.id && styles.editing)]}
                    >
                      {isUserComment && (editId === comment.id
                        ? <ActionIcon variant='subtle' onClick={() => setEditId(null)}><IconX /></ActionIcon>
                        : comment.history?.length > 0
                          ? <Tooltip label={`Last edit: ${dayjs(_.last(comment.history).edited_at).format('MMMM Do YYYY, h:mma')}`}>
                              <Indicator position='bottom-end' color='blue' label={comment.history.length} size='md' inline>
                                <ActionIcon variant='subtle' onClick={() => setEditId(comment.id)}>
                                    <IconEdit />
                                </ActionIcon>
                            </Indicator>
                            </Tooltip>
                          : <ActionIcon variant='subtle' onClick={() => setEditId(comment.id)}>
                              <IconEdit />
                            </ActionIcon>
                      )}

                      {(isUserComment || user?.roles.map(r => r.name).includes('ROLE_ADMIN')) &&
                        <ActionIcon variant='danger-subtle' onClick={() => remove(comment.id)}>
                          <IconTrash />
                        </ActionIcon>
                      }

                      <ActionIcon variant='subtle' className={styles.pin} onClick={() => update({ id: comment.id, pinned: !comment.pinned })}>
                        {comment.pinned ? <IconPinnedFilled /> : <IconPin />}
                      </ActionIcon>
                    </Group>
                  </Grid.Col>
                </Grid>
              )
            })}
          </Stack>
        </ScrollArea.Autosize>
        </Box>
        <Container py='xs'>
          <Grid>
            <Grid.Col span='auto'>
            <RichTextEditor
              styles={{
                typographyStylesProvider: { paddingLeft: 0, marginBottom: 0 }
              }}
              editor={editor}
            >
              <RichTextEditor.Content />
            </RichTextEditor>
            <Group gap='xs' justify='space-between'>
              <Text fz='sm'>Enter to post comment</Text>
              <Text fz='sm'>Shift+Enter to insert a new line</Text>
              </Group>
            </Grid.Col>
            <Grid.Col span='content' className={[styles.commentPostWrapper]}>
              <Button.Group mb='lg'>
                <Button variant='filled' onClick={() => post()}><IconSend /></Button>
                <Dropdown target={<Button variant='light' px='0.15rem'><IconChevronDown /></Button>} position='bottom-end'>
                  <Stack>
                    <Switch
                      label='Hide unpinned comments'
                      checked={filterUnpinned}
                      onChange={(ev) => setFilterUnpinned(ev.currentTarget.checked)}
                      />
                    <Switch
                      label='Automatically pin comments'
                      checked={autoPin}
                      onChange={(ev) => setAutoPin(ev.currentTarget.checked)}
                      />
                  </Stack>
                </Dropdown>
              </Button.Group>
            </Grid.Col>
            <Grid.Col py='xxs' span={12}>
            </Grid.Col>
          </Grid>
        </Container>
      </Flex>
    </Content>
  )
}

function CommentTags ({ comment, tags, setTags, hasPermission }) {
  const [commentTags, setCommentTags] = useState(comment.tags); // The comments current tags
  const [addedTag, setAddedTag] = useState(null);
  const [removedTag, setRemovedTag] = useState(null);
  const [limit, setLimit] = useState(0);
  const [searchValue, setSearchValue] = useState('')
  const ref = useRef(null)

  useEffect(() => {
    if (addedTag !== null) {
      addTag(comment.id, addedTag)
    }
  }, [addedTag])

  useEffect(() => {
    if (removedTag !== null) {
      removeTag(comment.id, removedTag)
    }
  }, [removedTag])

  // Fired when a tag is removed or added
  const onChange = (values) => {
    console.log(values)
    // Compare current values to the initial values to check if a tag was added
    const addedTag = _.difference(values, commentTags)
    if (_.size(addedTag) > 0) {
      setAddedTag(addedTag[0])
    }

    // Compare initial values to the current values to check if a tag was removed
    const removedTag = _.difference(commentTags, values)
    if (_.size(removedTag) > 0) {
      setRemovedTag(removedTag[0])
    }

    setCommentTags(values)
  }

  const onCreate = (tag) => {
    if (tags.includes(tag)) return
    // Since the backend uses the findOrCreate method we can reuse the same endpoint
    setAddedTag(tag)

    // Set tags so they can be used in other comments without refreshing
    setTags((current) => [...current, tag]);
    setCommentTags((current) => [...current, tag])
  }

  const search = (input) => {
    setSearchValue(input)
    setLimit(input === '' ? 0 : 20)
  }

  return (
      <TagsInput
        ref={ref}
        className={[styles.commentTags]}
        variant='unstyled'
        disabled={!hasPermission}
        leftSection={<ActionIcon variant='subtle' onClick={() => { ref.current.focus() }}><IconTag /></ActionIcon>}
        leftSectionProps={{ className: styles.focusTags }}
        data={tags}
        value={commentTags}
        onSearchChange={search}
        limit={limit}
        onChange={(values) => onChange(values)}
        searchValue={searchValue}
        onOptionSubmit={(query) => onCreate(query)}
        onBlur={() => setSearchValue('')}
        aria-label="comment tags"
      />
  )
}
