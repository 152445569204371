import {
  useGetAssignedCyclePhasesQuery,
  useGetCycleApplicantsQuery,
  useGetCycleStageGenerationStatusQuery
} from '../../../../redux/query/hire/cyclesApi.slice'
import { useGetLoggedInAccountQuery } from '../../../../redux/query/account/accountsApi.slice';
import { isGranted } from '../../../../util/account/account.util';

const limit = -1

export function useAllActiveCycleApplicants (cycleId, params = null, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCycleApplicantsQuery({ ...(params ?? {}), cycleId, limit }, { skip })
  return [data ?? null, querying, loading]
}

export function useCycleAssignedPhases (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetAssignedCyclePhasesQuery(cycleId, { skip })
  return [data ?? null, querying, loading]
}

export function useCycleStageGenerationStatus (cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetCycleStageGenerationStatusQuery(cycleId, { skip })
  return [data ?? null, querying, loading]
}

/**
 * @returns {[boolean, boolean]}
 */
export function useIsAdmin () {
  const { data: account, isLoading: accountLoading } = useGetLoggedInAccountQuery()
  return [account ? isGranted(account, 'ROLE_ADMIN') : false, accountLoading]
}
