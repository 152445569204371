import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter, createSelector
} from '@reduxjs/toolkit'

import { beginPublishedAssessment } from '../../js/api/published_assessment_repository';
import { reduxFormatPagesFromJson } from './PublishedAssessmentState';
import { QueryState } from './util';
import { AssessmentResponse, getResponsePrimaryDataKey } from '../../js/generated/enums/AssessmentResponse';

/**
 * @type {EntityAdapter<{PublishedAssessment}, string>}
 */
const assessmentsAdapter = createEntityAdapter()

const initialState = assessmentsAdapter.getInitialState({
  status: QueryState.Idle,
  error: null,
  errorData: null,
  lastAnsweredQuestionIsFreeResponse: false,
  lastAutoZoomedQuestionIsContent: false
})

export const loadStaticAssessment = createAsyncThunk('assessments/loadStaticAssessment', async (loadParams) => {
  const { startPageIndex, recordResponse, submitUrl, timeLimit, displayNumbers, assessmentId, auth, pages, logics, forceFocusReveal, pdfConfig } = loadParams
  const forceShowAll = !forceFocusReveal
  console.info('Loading static assessment questions.', assessmentId, forceShowAll, pdfConfig, pages)
  const payload = reduxFormatPagesFromJson(pages, logics ?? {}, startPageIndex, recordResponse, submitUrl, timeLimit, displayNumbers, assessmentId, auth, pdfConfig)
  if (forceShowAll) {
    for (const page of payload.pages) {
      page.show = true
    }
    for (const block of payload.blocks) {
      block.show = true
    }
    for (const question of payload.questions) {
      question.show = true
    }
    for (const respondentAnswer of payload.respondentAnswers) {
      respondentAnswer.additionalAnswer = ' '
      respondentAnswer.answered = true
    }
  }
  console.info('Static assessment returning payload.', payload)
  return payload
})

export const fetchPublishedAssessmentQuestions = createAsyncThunk('assessments/fetchPublishedAssessmentQuestions', async (fetchParams, { dispatch, rejectWithValue }) => {
  const { fetchUrl, startPageIndex, recordResponse, submitUrl, timeLimit, displayNumbers, assessmentId, auth, isStatic, pages } = fetchParams
  if (isStatic && pages && !recordResponse) {
    console.debug('Fetching static assessment', isStatic, pages, assessmentId)
    const retVal = await dispatch(loadStaticAssessment(fetchParams))
    console.debug('Static assessment returning retval', retVal.payload)
    return retVal.payload
  }
  console.info('Fetching assessment questions.', fetchUrl, auth)
  const response = await beginPublishedAssessment(fetchUrl, Object.values(auth ?? {})?.length ? auth : null)
  console.info('Got assessment questions response.', response?.error, response?.responseType, response?.data, response)
  if (!response) {
    console.warn('No response from fetch questions - was cancelled?', response)
    return false
  }
  switch (response.responseType) {
    case AssessmentResponse.Start:
    case AssessmentResponse.Continue: {
      const data = response.data?.[getResponsePrimaryDataKey(response.responseType)] ?? null
      console.debug('Fetch published assessment questions response extracted data.', data, response.responseType, response)
      return reduxFormatPagesFromJson(data?.pages ?? [], data?.logics ?? {}, startPageIndex, recordResponse, submitUrl, timeLimit, displayNumbers, assessmentId, auth)
    }
    default: {
      return rejectWithValue(response)
    }
  }
})

const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState: initialState,
  reducers: {
    answerQuestion: (state, action) => {
      const { id: questionId, assessmentId, isAdditional, isMultiple } = action.payload
      const existingAssessment = state.entities[assessmentId]
      console.debug('Called assessment slice answer question', action, questionId, existingAssessment?.focus.lastAnsweredQuestionId)
      if (existingAssessment) {
        const isFreeResponse = !!isAdditional || !!isMultiple
        if (isFreeResponse !== state.lastAnsweredQuestionIsFreeResponse) {
          state.lastAnsweredQuestionIsFreeResponse = isFreeResponse
        }
        if (state.lastAutoZoomedQuestionIsContent) {
          state.lastAutoZoomedQuestionIsContent = false
        }

        if (existingAssessment.focus.lastAnsweredQuestionId !== questionId) {
          existingAssessment.focus.lastAnsweredQuestionId = questionId
        }
      }
    },
    previousQuestionAnswered: (state, action) => {
      console.debug('Processing previous question answered for potential assessment transition to target.', action.payload)
      const { questionId, assessmentId, answerable, hideFromLogic, previousIsContent, previousId } = action.payload
      const existingAssessment = state.entities[assessmentId]
      if (existingAssessment) {
        if (!(existingAssessment.focus.transitionToQuestionId === questionId)) {
          if (!hideFromLogic) {
            console.debug(
              'Auto-transition to question unless previousIsContent or lastAutoZoomedQuestionIsContent.',
              previousIsContent,
              state.lastAutoZoomedQuestionIsContent,
              existingAssessment.focus.lastAnsweredQuestionId,
              previousId,
              questionId
            )
            if (!previousIsContent && !state.lastAutoZoomedQuestionIsContent) {
              if (state.lastAnsweredQuestionIsFreeResponse) {
                console.info(
                  'Not auto transitioning to target - last answered question is free response. Setting prompt transition question id.',
                  questionId,
                  existingAssessment.focus.lastAnsweredQuestionId
                )
                existingAssessment.focus.promptTransitionQuestionId = questionId
                state.lastAutoZoomedQuestionIsContent = !answerable
              } else {
                console.info(
                  'Setting auto transition target.',
                  questionId
                )
                existingAssessment.focus.transitionToQuestionId = questionId
                existingAssessment.focus.promptTransitionQuestionId = null
                state.lastAutoZoomedQuestionIsContent = !answerable
              }
            } else {
              console.info('Skipping auto transition to question due to previous is content.', questionId, previousId, previousIsContent)
            }
          } else {
            console.info('Skipping auto transition to question due to logic.', questionId, existingAssessment.focus)
          }
        }
      }
    },
    transitionToQuestion: (state, action) => {
      console.info('Processing manual request to auto transition to question.', action.payload)
      const { questionId, assessmentId, doNotOverwritePrompt } = action.payload
      const existingAssessment = state.entities[assessmentId]
      if (existingAssessment?.focus) {
        if (doNotOverwritePrompt && (state.lastAnsweredQuestionIsFreeResponse || existingAssessment.focus.promptTransitionQuestionId)) {
          console.info('Not manually transitioning to question - prompting instead.', questionId, state.lastAnsweredQuestionIsFreeResponse, existingAssessment.focus)
          existingAssessment.focus.promptTransitionQuestionId = questionId
          state.lastAutoZoomedQuestionIsContent = false
        } else {
          console.info('Beginning manually triggered transition to question.', questionId, existingAssessment.focus)
          existingAssessment.focus.transitionToQuestionId = questionId
          existingAssessment.focus.promptTransitionQuestionId = null
          state.lastAutoZoomedQuestionIsContent = false
        }
      }
    },
    transitionToComplete: (state, action) => {
      console.debug('Processing completed update for previous assessment transition to target.', action.payload)
      const { questionId, assessmentId } = action.payload
      const existingAssessment = state.entities[assessmentId]
      if (existingAssessment) {
        if (existingAssessment.focus.transitionToQuestionId === questionId) {
          console.debug('Auto-transition to question completed.', existingAssessment.focus, questionId)
          existingAssessment.focus.transitionToQuestionId = null
          existingAssessment.focus.promptTransitionQuestionId = null
        } else {
          console.info(
            'Transition to question id did not match on complete - another question transitioned in the meantime?',
            existingAssessment.focus,
            questionId
          )
        }
      }
    },
    onlySkippedQuestionsLeft: (state, action) => {
      console.info('Processing only skipped questions left update for assessment.', action.payload)
      const { assessmentId } = action.payload
      const existingAssessment = state.entities[assessmentId]
      if (existingAssessment) {
        if (!existingAssessment.allowDeclineAnswers) {
          console.info('Allowing declining answers for assessment.', assessmentId)
          existingAssessment.allowDeclineAnswers = true
        } else {
          console.warn(
            'Only skipped questions left called multiple times - can happen if questions revealed by logic.',
            existingAssessment.allowDeclineAnswers,
            action.payload
          )
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublishedAssessmentQuestions.fulfilled, (state, action) => {
        console.debug('assessmentsSlice builder fulfilled', state, action)
        assessmentsAdapter.upsertOne(state, action.payload.assessment)
        state.status = QueryState.Succeeded
        if (state.error) {
          state.error = null
          state.errorData = null
        }
      })
      .addCase(fetchPublishedAssessmentQuestions.rejected, (state, action) => {
        console.warn('assessmentsSlice builder rejected', state, action, action.payload.responseType)
        state.status = action.payload.responseType === AssessmentResponse.UnexpectedError ? QueryState.Failed : QueryState.Rejected
        state.error = action.payload.responseType
        state.errorData = action.payload.data
      })
      .addCase(fetchPublishedAssessmentQuestions.pending, (state, action) => {
        state.status = QueryState.Loading
      })
  }
})

export const { answerQuestion, previousQuestionAnswered, transitionToQuestion, transitionToComplete, onlySkippedQuestionsLeft } = assessmentsSlice.actions

export default assessmentsSlice.reducer

export const { selectAll: selectAllAssessments, selectById: selectAssessmentById, selectIds: selectAssessmentIds, selectEntities: selectAssessmentsMap } =
  assessmentsAdapter.getSelectors(state => state.assessments)

export const selectIsTransitionToTarget = createSelector(
  [selectAssessmentById, (state, assessmentId, questionId) => questionId],
  (assessment, questionId) => assessment?.focus?.transitionToQuestionId === questionId
)

export const selectAssessmentHasTransitionToTarget = createSelector(
  [selectAssessmentById],
  (assessment) => !!(assessment?.focus?.transitionToQuestionId)
)

export const selectAllowDeclineAnswers = createSelector(
  [selectAssessmentById],
  (assessment) => assessment?.allowDeclineAnswers
)

export const selectPromptTransitionToQuestionId = createSelector(
  [selectAssessmentById],
  (assessment) => assessment?.focus?.promptTransitionQuestionId ?? null
)
