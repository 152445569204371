/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useRef, useState } from 'react'
import { useFirstRender } from '../../../util/Hooks'
import { TextInput } from '@mantine/core'
import styles from '../ReactTable.module.scss'
import { IconSearch } from '@tabler/icons-react'
import PropTypes from 'prop-types'

export default function SearchBar ({ value, onChange }) {
  const [searchString, setSearchString] = useState(value ?? '')
  const [isSearching, setIsSearching] = useState(false)
  const firstRender = useFirstRender()
  const searchStringRef = useRef(searchString)

  useEffect(() => {
    if (firstRender) return
    setIsSearching(true)
    const timer = setTimeout(() => {
      searchStringRef.current = searchString
      onChange(searchString)
      setIsSearching(false)
    }, 300, searchString)
    return () => clearTimeout(timer)
  }, [searchString])

  useEffect(() => {
    if ((value ?? '') !== searchStringRef.current) {
      searchStringRef.current = value
      setSearchString(value)
    }
  }, [value])

  return (
    <TextInput
      value={searchString}
      placeholder='Search'
      onChange={ev => { setSearchString(ev.target.value) }}
      rightSection={<IconSearch size='1rem' className={isSearching ? styles.waiting : null} />}
    />
  )
}

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}
