import { ApiMethods, apiSlice, ApiTags } from '../../../react/api'

export const applicantsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getApplicant: builder.query({
      query: id => `applicants/${id}`,
      providesTags: (result, error, id) => [{ type: ApiTags.Applicants, id: id }]
    }),
    editStatus: builder.mutation({
      query: ({ status, applicants, hiredDate, cycleId }) => {
        const formData = new FormData()
        formData.append('status-name', status)
        formData.append('applicant-ids[]', applicants)
        formData.append('hired-date', hiredDate ?? '')
        console.debug('Dispatching edit status action.', { formData, status, applicants, hiredDate, cycleId })
        return {
          url: 'statuses/applicants/bulkedit',
          method: ApiMethods.Post,
          body: formData
        }
      },
      invalidatesTags: (result, error, { applicants: applicantsParam }) => [
        ...applicantsParam.map(applicantId => ({ type: ApiTags.Applicants, id: applicantId }))
      ]
    })
  })
})

export const { useGetApplicantQuery, useEditStatusMutation } = applicantsApiSlice
