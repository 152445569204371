import Base from '../../../layout/Base';
import { Info } from './Info';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ActionIcon, Anchor, Box, Button, Card, Flex, Group, Indicator, Loader, Modal, ScrollArea, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconCircleXFilled, IconClipboardData, IconFile, IconFlag, IconFlagOff, IconId, IconList, IconMail, IconSquareX, IconTrash, IconUserCircle } from '@tabler/icons-react';
import Discussion from '../Discussion';
import { useAccount } from '../../../util/Hooks';
import { Scores } from './Scores';
import { useDisclosure, useWindowEvent } from '@mantine/hooks';
import { getApplicant, getFlagContent, getRezview } from '../../../../js/api/applicant_repository';
import { getCycleModules, getCycleStatuses } from '../../../../js/api/cycle_repository';
import PropTypes from 'prop-types'
import axios from 'axios';

const ICON_SIZE = '2rem'

const urls = {
  applicantReport: (cycleId, applicantId) => `/hire/cycles/${cycleId}/applicants/${applicantId}/report`,
  files: (cycleId, applicantId) => `/hire/report-results/${cycleId}/applicants/${applicantId}/files`,
  applicantResponses: (cycleId, applicantId) => `/cycles/${cycleId}/applicants/${applicantId}/responses`,
  applicantDelete: (cycleId, applicantId) => `/hire/report-results/${cycleId}/applicants/${applicantId}/delete`,
  applicantContact: (cycleId, applicantId) => `/cycles/${cycleId}/contact/${applicantId}`,
  ksResponses: (cycleId, applicantId) => `/cycles/${cycleId}/applicants/${applicantId}/responses`
}

export function ApplicantCardWrapper () {
  const cid = document.getElementById('applicant-card').dataset.cycleId
  const simple = 'simple' in document.getElementById('applicant-card').dataset

  return (
    <Base>
      <ApplicantCardFrame cid={cid} isSImple={simple} />
    </Base>
  )
}

export function ApplicantCardFrame ({ applicantId, cid, isSimple = false, onClose }) {
  const [applicant, setApplicant] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [rezview, setRezview] = useState(null)
  const [flagContent, setFlagContent] = useState('')
  const [modules, setModules] = useState([])
  const [statuses, setStatuses] = useState([])
  const account = useAccount()
  const cancelSourceRef = useRef(null)

  const [cycleId, setCycleId] = useState(cid)

  const isReady = !!(applicant && rezview && flagContent && modules.length > 0 && statuses.length > 0)

  const fetchApplicant = useCallback((id) => {
    if (!id) return
    setFetching(true)

    if (cancelSourceRef.current) {
      cancelSourceRef.current.cancel('Operation canceled due to new request.')
    }

    cancelSourceRef.current = axios.CancelToken.source()
    getApplicant(id, cancelSourceRef.current.token)
      .then((response) => {
        setApplicant(response)
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.debug(`Cancelled fetch for applicant (id: ${id})`)
        } else {
          console.error(`Failed to fetch applicant (id: ${id})`, err)
        }
      })

    getRezview(id)
      .then((response) => {
        setRezview(response)
      })
      .catch((err) => {
        console.error(`Failed to fetch rezview for applicant (id: ${id})`, err)
      })

    getFlagContent(id)
      .then((response) => {
        setFlagContent(response)
      })
      .catch((err) => {
        console.error(`Failed to fetch flag report for applicant (id: ${id})`, err)
      })
  }, [])

  useEffect(() => {
    if (!applicant) return
    if (applicant.cycle_id !== cycleId) {
      setFetching(true)
      setModules([])
      setStatuses([])
      setCycleId(applicant.cycle_id)
    }
  }, [applicant, cycleId, applicantId])

  useEffect(() => {
    if (!cycleId) return
    getCycleModules(cycleId)
      .then(response => {
        if (response.data.length === 0) {
          setModules([])
        } else {
          setModules(response.data.filter(module => (module.type.name !== 'Text' && module.visible === 1)).map(module => ({ id: module.id, name: module.name })))
        }
      })
      .catch((err) => {
        console.error(`Failed to fetch cycle (cycleId: ${cycleId}) modules`, err)
      })

    getCycleStatuses(cycleId)
      .then(response => {
        setStatuses(response.items.map(status => ({ value: status.id, label: status.name, tier: status.tier })) ?? [])
      })
      .catch((err) => {
        console.error(`Failed to fetch cycle (cycleId: ${cycleId}) statuses`, err)
      })
  }, [cycleId])

  useEffect(() => {
    if (!applicant && !fetching) return
    setFetching(!isReady)
  }, [isReady, fetching, applicant])

  useEffect(() => {
    if (!applicantId) return
    setApplicant(null)
    fetchApplicant(applicantId)
  }, [applicantId, fetchApplicant])

  useWindowEvent('appcard:opened', ({ detail }) => {
    setApplicant(null)
    fetchApplicant(detail.id)
  })

  return (
    <Base>
    <Card id='card' mih='70vh' mah='90vh' w='35rem' p={0}>
      {fetching
        ? <LoadingScreen />
        : (isReady
            ? <ApplicantCard
                  applicant={applicant}
                  rezview={rezview}
                  modules={modules}
                  statuses={statuses}
                  account={account}
                  flagContent={flagContent}
                  isSimple={isSimple}
                  onClose={onClose}
                  />
            : applicant ? <FailureScreen /> : <IdleScreen />)
      }
      </Card>
    </Base>
  )
}

export function ApplicantCard ({ applicant, rezview, modules, statuses, account, flagContent, isSimple = false, onClose }) {
  const [editing, { open, close }] = useDisclosure(false)
  const [rezviewModal, { open: openRezview, close: closeRezview }] = useDisclosure(false)
  const [flagsModal, { open: openFlags, close: closeFlags }] = useDisclosure(false)

  const isAdmin = useMemo(() => {
    if (!account.access) return false
    return account.access.ADMIN || account.access.DEVELOPER
  }, [account])

  const closeCard = () => {
    if (onClose) {
      onClose()
    } else {
      const event = new CustomEvent('appcard:close')
      window.dispatchEvent(event)
    }
  }

  const flagModalTitle = (
    <Text fw='bold'>Flags: {applicant.first_name} {applicant.last_name}</Text>
  )

  return (
    <>
      <ScrollArea.Autosize type='auto' mah='90vh'>
        <Box p='xs'>
          <Group px='xs' mb='xs' justify='space-between'>
            <Group gap='xs'>
              <CardAction icon={<IconId size={ICON_SIZE} />} label='Show summary' onClick={openRezview} disabled={!rezview} />
              {applicant?.flags.length > 0
                ? <Indicator size={16} offset={6} label={applicant.flags.length}>
                    <CardAction
                      icon={<IconFlag size={ICON_SIZE} />}
                      label='Show flags'
                      disabled={!flagContent}
                      onClick={openFlags}
                    />
                  </Indicator>
                : <CardAction icon={<IconFlagOff size={ICON_SIZE} />} label='No flags' />
              }
              <CardAction
                icon={<IconClipboardData size={ICON_SIZE} />}
                label='Candidate Report'
                component={Anchor}
                href={urls.applicantReport(applicant.cycle_id, applicant.id)}
                />
              {
                (isAdmin && applicant?.ks_respondent?.id) &&
                <CardAction icon={<IconList size={ICON_SIZE} />} label='KeySurvey Data' component={Anchor} href={urls.ksResponses(applicant.cycle_id, applicant.id)} />
              }
            </Group>
            <Group>
              <CardAction icon={<IconFile size={ICON_SIZE} />} label='Files' component={Anchor} href={urls.files(applicant.cycle_id, applicant.id)} />
              <CardAction icon={<IconMail size={ICON_SIZE} />} label='Contact Applicant' component={Anchor} href={urls.applicantContact(applicant.cycle_id, applicant.id)} />
              <CardAction icon={<IconTrash size={ICON_SIZE} />} label='Delete/Refresh Applicant' variant='danger-subtle' component={Anchor} href={urls.applicantDelete(applicant.cycle_id, applicant.id)} />
              {!isSimple && <CardAction icon={<IconSquareX size={ICON_SIZE} />} label='Close Card' onClick={closeCard} />}
            </Group>
          </Group>
          {!editing && <Flex justify='flex-end' px='xs'><Button onClick={open}>Edit</Button></Flex>}
          <Info applicant={applicant} editing={editing} closeEdit={close} isAdmin={isAdmin} statuses={statuses} />
          <Flex justify='center' p='xs'>
            <Scores applicant={applicant} editing={editing} modules={modules} statuses={statuses} />
          </Flex>
          <Box hidden={editing}>
            <Discussion applicantId={applicant.id} user={account} />
          </Box>
        </Box>
      </ScrollArea.Autosize>

      <Modal opened={flagsModal} onClose={closeFlags} title={flagModalTitle}>
        {flagContent && <div dangerouslySetInnerHTML={{ __html: flagContent }}></div>}
      </Modal>

      <Modal opened={rezviewModal} onClose={closeRezview} size='xl'>
        {rezview && <div dangerouslySetInnerHTML={{ __html: rezview }}></div>}
      </Modal>
    </>
  )
}

function CardAction ({ icon, label, ...props }) {
  return (
    <Tooltip label={label}>
      <ActionIcon size='xl' {...props}>
        {icon}
      </ActionIcon>
    </Tooltip>
  )
}

function LoadingScreen () {
  return (
    <Flex align='center' justify='center' h='70vh'>
      <Stack align='center' justify='center'>
        <h1>Searching for applicant...</h1>
        <Loader type='dots' />
      </Stack>
    </Flex>
  )
}

function FailureScreen () {
  return (
    <Flex align='center' justify='center' h='70vh'>
      <Stack align='center' justify='center'>
        <h1>Failed to load applicant</h1>
        <ThemeIcon size='xl' color='red' variant='subtle'><IconCircleXFilled size='4rem' /></ThemeIcon>
      </Stack>
    </Flex>
  )
}

function IdleScreen () {
  return (
    <Flex align='center' justify='center' h='70vh'>
      <Stack align='center' justify='center'>
        <h1>Select an applicant from the table</h1>
        <ThemeIcon size='xl' variant='subtle'><IconUserCircle size='4rem' /></ThemeIcon>
      </Stack>
    </Flex>
  )
}

ApplicantCardFrame.propTypes = {
  applicantId: PropTypes.number,
  cid: PropTypes.string,
  isSimple: PropTypes.bool,
  onClose: PropTypes.func
}

ApplicantCard.propTypes = {
  applicant: PropTypes.object,
  rezview: PropTypes.string,
  modules: PropTypes.array,
  statuses: PropTypes.array,
  account: PropTypes.object,
  flagContent: PropTypes.string,
  isSimple: PropTypes.bool,
  onClose: PropTypes.func
}

CardAction.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string
}
