import React from 'react';
import { getDescription, StageReplacementType } from '../../../../../js/generated/enums/StageReplacementType';
import { StageReplacementNote } from '../../../../../js/generated/enums/StageReplacementNote';
import { showNotification } from '@mantine/notifications';
import { Box, Text } from '@mantine/core';

/**
 * @typedef {object} Phase
 * @property {int} id also correlates to position
 * @property {string} name
 */

/**
 * @typedef {object} SourceStageConfig
 * @property {?int} log_id Id or null if new
 * @property {Phase} phase
 * @property {?bool} proctored
 * @property {?int} time_limit
 * @property {?bool} collect_demographics
 */

/**
 * @typedef {object} SourceUnpublishedAssessment
 * @property {int} id
 * @property {string} name
 * @property {string} created
 */

/**
 * @typedef {object} SourcePublishedAssessment
 * @property {int} id
 * @property {string} name
 * @property {string} created
 * @property {SourceUnpublishedAssessment} unpublished_assessment
 */

/**
 * @typedef {object} SourceGeneratedCycleStage
 * @property {?int} log_id Id or null if new
 * @property {SourceStageConfig} config
 * @property {SourcePublishedAssessment} assessment
 * @property {int} index
 */

/**
 * @typedef {object} SourceAutoAssignedStageChange
 * @property {boolean} valid
 * @property {boolean} phase_change
 * @property {StageReplacementType} type
 * @property {SourceGeneratedCycleStage} new
 */

/**
 * @typedef {object} SourceStageGenerationChanges
 * @property {boolean} any Any required review due to phase changes or missing matches
 * @property {Object.<int, SourceAutoAssignedStageChange>} auto Old stage id to auto-assigned stage transfer, where possible
 * @property {SourceGeneratedCycleStage[]} matchNew Unmatched new stages
 * @property {SourceGeneratedCycleStage[]} matchOld Unmatched old stages (action required)
 */

/**
 * @typedef {object} SourceStageGenerationChanges
 * @property {boolean} any Any required review due to phase changes or missing matches
 * @property {Object.<int, SourceAutoAssignedStageChange>} auto Old stage id to auto-assigned stage transfer, where possible
 * @property {SourceGeneratedCycleStage[]} matchNew Unmatched new stages
 * @property {SourceGeneratedCycleStage[]} matchOld Unmatched old stages (action required)
 */

/**
 * @typedef {object} SourceStageGenerationModuleWarning
 * @property {string} name module name
 * @property {int} id moduleInfo id
 * @property {boolean} visible moduleInfo visible === 1
 */

/**
 * @typedef {object} SourceStageGenerationAssessmentError
 * @property {string} name published assessment name
 * @property {int} id published assessment id
 * @property {int} repeatCount
 */

/**
 * @typedef {object} SourceStageGenerationStatus
 * @property {boolean} valid Whether stages can be generated without making changes elsewhere
 * @property {Object.<string, SourceStageGenerationModuleWarning[]|int[]>|[]} warnings Other potential issues with current setup not resolvable through modal (only elsewhere) - key = StageReplacementNote or is empty array
 * @property {Object.<string, SourceStageGenerationModuleWarning[]|SourceStageGenerationAssessmentError[]|string[]>|[]} errors Issues with current setup (only resolvable through other pages) - key = StageReplacementNote or is empty array
 * @property {Object.<int, int[]>} phase_ids_to_stage_indexes Object mapping phase id to all auto-associated stage indexes
 * @property {Object.<int, SourcePublishedAssessment>} stage_indexes_to_assessment
 * @property {SourceStageGenerationChanges} changes
 * @property {boolean} manual Whether any manual confirmation required
 * @property {SourceGeneratedCycleStage[]} old Current cycle stages
 * @property {SourceGeneratedCycleStage[]} new Transient new stages (not saved to db)
 */

/**
 * @typedef {object} TransientStageConfig
 * @property {int} id Back-end id or negative fake id if new (not saved to db)
 * @property {int} stageId Back-end id or negative fake id of stage if new (not saved to db)
 * @property {int} phaseId
 * @property {string} phaseName
 * @property {?bool} proctored
 * @property {?int} timeLimit
 * @property {?bool} collectDemographics
 */

/**
 * @typedef {object} TransientStagePublishedAssessment
 * @property {int} id
 * @property {string} name
 * @property {string} created
 * @property {SourceUnpublishedAssessment} unpublishedAssessment
 */

/**
 * @typedef {object} TransientStage
 * @property {int} id Back-end id or negative fake id if new (not saved to db)
 * @property {TransientStageConfig} config
 * @property {TransientStagePublishedAssessment} assessment
 * @property {int} index
 * @property {bool} isNew
 * @property {bool} error
 */

/**
 * @typedef {object} TransientStageReassignment
 * @property {int} newId Negative fake-id of new stage replacing old stage - replaced with source index upon submission.
 * @property {int} oldId
 * @property {?StageReplacementType} type
 * @property {int} oldPhaseId
 * @property {int} newPhaseId
 * @property {boolean} phaseChange Whether new stage phase is different from old phase
 * @property {int} index Index of new stage
 * @property {int} oldIndex Index of old stage
 * @property {boolean} publishedAssessmentChanged
 * @property {boolean} unpublishedAssessmentChanged
 */

/**
 * @typedef {object} CycleStageGenerationPhase
 * @property {int} id
 * @property {string} name
 * @property {int[]} new associated new stage fake ids
 * @property {int[]} old associated old stage fake ids
 */

/**
 * @typedef {object} StageGenerationState
 * @property {boolean} loaded
 * @property {boolean} manualResolved
 * @property {boolean} resolvable
 * @property {boolean} warningsViewed
 * @property {boolean} locked
 * @property {boolean} generationNeeded
 * @property {Map.<int, TransientStage>} stages
 * @property {int[]} new new stage fake Ids
 * @property {int[]} old old stage Ids
 * @property {Map.<int, ?TransientStageReassignment>} oldToNewAssignments
 * @property {CycleStageGenerationPhase[]} phases
 * @property {Object.<string, SourceStageGenerationModuleWarning[]|SourceStageGenerationAssessmentError[]|string[]>} sourceErrors key = StageReplacementNote
 * @property {Object.<string, SourceStageGenerationModuleWarning[]|int[]>} sourceWarnings key = StageReplacementNote
 */

/**
 * @returns {StageGenerationState}
 */
function makeDefaultStageGenerationState () {
  return {
    loaded: false,
    manualResolved: false,
    resolvable: false,
    warningsViewed: false,
    locked: false,
    generationNeeded: true,
    stages: new Map(),
    new: [],
    old: [],
    oldToNewAssignments: new Map(),
    phases: [],
    sourceErrors: { [StageReplacementNote.insufficientData]: [] },
    sourceWarnings: {}
  }
}

export const stageReplacementTypeIdToName = Object.freeze(
  Object.fromEntries(
    Object.entries(StageReplacementType)
      .map(([key, value]) => [value, key.split(/(?=[A-Z])/).join(' ')])
  )
)

export const stageReplacementTypeIdToDescription = Object.freeze(
  Object.fromEntries(
    Object.entries(StageReplacementType)
      .map(([key, value]) => [value, getDescription(StageReplacementType[key])])
  )
)

export function getStageReplacementTypeOptions (replacement, stages) {
  if (!replacement) {
    return null
  }
  const publishedAssessmentChanged = replacement.publishedAssessmentChanged
  const unpublishedAssessmentChanged = replacement.unpublishedAssessmentChanged
  const validReplacementTypes = getValidReplacementTypesForChanges({ publishedAssessmentChanged, unpublishedAssessmentChanged })
  return validReplacementTypes.map((option) => ({ value: option.toString(), label: stageReplacementTypeIdToName[option], description: (<Box>{stageReplacementTypeIdToDescription[option].split('.').filter(elem => !!elem).map(elem => (<Text key={elem} my='sm'>{elem + '.'}</Text>))}</Box>) }))
}

/**
 * @param {?SourceStageGenerationStatus} sourceStageGenerationStatus
 * @returns {StageGenerationState}
 */
export function createInitialStageGenerationState (sourceStageGenerationStatus = null) {
  const stageGenerationStatus = filterUselessStageGenerationWarnings(sourceStageGenerationStatus)
  const newState = makeDefaultStageGenerationState()
  if (!stageGenerationStatus || !Object.keys(stageGenerationStatus).length) {
    return newState
  }
  let manualResolved = !stageGenerationStatus.manual
  let anyStageError = false
  let nextFakeId = -1
  const stages = new Map()
  const newStageFakeIds = []
  const oldStageIds = []
  const newStageIndexToFakeId = new Map()
  const phases = {}

  const initStage = (stage, isNew = false) => {
    let isError = false
    let stageId = stage.log_id ?? null
    if (!stageId) {
      stageId = nextFakeId
      nextFakeId -= 1
      if (!isNew) {
        isError = true
      }
    }
    let configId = stage.config.log_id ?? null
    if (!configId) {
      configId = nextFakeId
      nextFakeId -= 1
      if (!isNew) {
        isError = true
      }
    }
    anyStageError = anyStageError || isError
    const stagePhaseId = stage.config.phase.id
    const stagePhaseName = stage.config.phase.name
    if (!(phases[stagePhaseId] ?? false)) {
      phases[stagePhaseId] = { name: stagePhaseName, id: stagePhaseId, new: [], old: [] }
    }
    if (isNew) {
      phases[stagePhaseId].new.push(stageId)
    } else {
      phases[stagePhaseId].old.push(stageId)
    }
    return {
      id: stageId,
      config: {
        id: configId,
        stageId: stageId,
        phaseId: stagePhaseId,
        phaseName: stagePhaseName,
        proctored: stage.config.proctored ?? null,
        timeLimit: stage.config.time_limit ?? null,
        collectDemographics: stage.config.collect_demographics ?? null
      },
      assessment: { ...stage.assessment, unpublishedAssessment: stage.assessment.unpublished_assessment },
      index: stage.index,
      isNew: isNew,
      error: isError
    }
  }

  for (const stage of stageGenerationStatus.old) {
    const stageData = initStage(stage, false)
    stages.set(stageData.id, stageData)
    oldStageIds.push(stageData.id)
  }
  for (const stage of stageGenerationStatus.new) {
    const stageData = initStage(stage, true)
    stages.set(stageData.id, stageData)
    if (newStageIndexToFakeId.has(stageData.index)) {
      stageData.error = true
      console.error('New stage\'s index is duplicated - already found in stage index lookup.', { stageGenerationStatus, stageData, newStageIndexToFakeId })
    }
    newStageIndexToFakeId.set(stageData.index, stageData.id)
    newStageFakeIds.push(stageData.id)
  }
  const oldToNewAssignments = new Map()
  for (const oldStage of stageGenerationStatus.old) {
    oldToNewAssignments.set(oldStage.log_id, null)
  }
  for (const [oldId, autoChange] of Object.entries(stageGenerationStatus.changes.auto)) {
    console.debug('Auto change', { oldId, autoChange })
    const newStageId = newStageIndexToFakeId.get(autoChange.new.index)
    const newStage = stages.get(newStageId)
    const intOldId = parseInt(oldId)
    const oldStage = stages.get(intOldId)
    if (!newStageId || !oldStage || !newStage?.isNew || oldStage?.isNew) {
      console.error('Unable to find old stage data for old stage id or new stage fake id for new stage index - forcing manual replacement.', { newStageId: newStageId, newStage: newStage, oldStage: oldStage, new: autoChange.new, indexLookup: newStageIndexToFakeId, stages: stages, sourceData: stageGenerationStatus })
      oldToNewAssignments.set(intOldId, null)
      manualResolved = false
    } else {
      const publishedAssessmentChanged = newStage.assessment.id !== oldStage.assessment.id
      const unpublishedAssessmentChanged = newStage.assessment.unpublishedAssessment?.id !== oldStage.assessment.unpublishedAssessment?.id
      const replacementType = validateReplacementType(autoChange.type, { publishedAssessmentChanged, unpublishedAssessmentChanged })
      if (replacementType !== (autoChange.type ?? null)) {
        console.warn('Replacement type provided from back-end was not valid - replaced', { replacementType, autoChange, stages, stageGenerationStatus })
        manualResolved = false
      }
      oldToNewAssignments.set(intOldId, {
        newId: newStageId,
        oldId: intOldId,
        type: replacementType,
        oldPhaseId: oldStage.config.phaseId,
        newPhaseId: newStage.config.phaseId,
        phaseChange: autoChange.phase_change,
        index: autoChange.new.index,
        oldIndex: oldStage.index,
        publishedAssessmentChanged: publishedAssessmentChanged,
        unpublishedAssessmentChanged: unpublishedAssessmentChanged
      })
    }
  }
  for (const unmatchedOld of stageGenerationStatus.changes.matchOld) {
    oldToNewAssignments.set(unmatchedOld.log_id, null)
    manualResolved = false
  }
  const resolvable = stageGenerationStatus.valid && !!newStageFakeIds.length && !Object.values(stageGenerationStatus.errors).length && !anyStageError
  const generationNeeded = !!(stageGenerationStatus.changes.any || stageGenerationStatus.manual || Object.values(stageGenerationStatus.errors).length || !stageGenerationStatus.old.length || !stageGenerationStatus.new.length || stageGenerationStatus.changes.matchOld.length || stageGenerationStatus.changes.matchNew.length || !stageGenerationStatus.valid)
  const returnState = {
    ...newState,
    warningsViewed: !Object.values(stageGenerationStatus.errors).length && !Object.values(stageGenerationStatus.warnings).length,
    loaded: !!newStageFakeIds.length,
    manualResolved: manualResolved && resolvable,
    resolvable: resolvable,
    generationNeeded: generationNeeded,
    stages: stages,
    new: newStageFakeIds,
    old: oldStageIds,
    oldToNewAssignments: oldToNewAssignments,
    phases: Object.values(phases).toSorted((a, b) => a.id - b.id),
    sourceErrors: Object.values(stageGenerationStatus.errors).length ? stageGenerationStatus.errors : {},
    sourceWarnings: Object.values(stageGenerationStatus.warnings).length ? stageGenerationStatus.warnings : {}
  }
  if (!Object.values(returnState.sourceErrors).length && !Object.values(returnState.sourceWarnings).length) {
    return stageGenerationStateReducer(returnState, { type: StageGenerationUpdate.CheckResolved })
  }
  return returnState
}

/**
 * Removes warnings for skipped parent modules which never have an assessment, such as baseline.
 * @param {?SourceStageGenerationStatus} stageGenerationStatus
 * @returns {?SourceStageGenerationStatus}
 */
function filterUselessStageGenerationWarnings (stageGenerationStatus) {
  const warningKey = 'Skipped Parent Modules With No Assessment';
  if (stageGenerationStatus?.warnings && Object.values(stageGenerationStatus.warnings).length && (warningKey in stageGenerationStatus.warnings)) {
    const skippedWarningModules = ['courtesy letter', 'notes', 'baseline']
    const newWarnings = {
      ...stageGenerationStatus.warnings,
      [warningKey]: stageGenerationStatus.warnings[warningKey].filter((warning) => !skippedWarningModules.includes((warning.name ?? '').toLowerCase()))
    }
    if (newWarnings[warningKey].length === stageGenerationStatus.warnings[warningKey].length) {
      return stageGenerationStatus
    }
    if (!newWarnings[warningKey].length) {
      delete newWarnings[warningKey]
    }
    return { ...stageGenerationStatus, warnings: newWarnings }
  }
  return stageGenerationStatus
}

export const StageGenerationUpdate = Object.freeze({
  AssignStageReplacement: 'assign-stage-replacement',
  ChangeStageReplacementType: 'change-stage-replacement-type',
  SetNewStageConfigProctored: 'set-new-stage-proctored',
  SetNewStageConfigTimeLimit: 'set-new-stage-time-limit',
  SetNewStageConfigCollectDemographics: 'set-new-stage-collect-demographics',
  CheckResolved: 'check-resolved',
  Generate: 'generate-stages'
})

/**
 * @param {*&StageGenerationState} state
 * @param {object} action
 * @param {StageGenerationUpdate} action.type
 * @param {int?} action.targetStage
 * @param {int?} action.replacementStage
 * @param {int?} action.replacementType
 * @param {boolean|int|null} action.value
 * @param {boolean?} action.complete
 * @param {boolean?} action.notifyIncomplete
 * @param {boolean?} action.isWarningView
 * @returns {*&StageGenerationState}
 */
export function stageGenerationStateReducer (state, action) {
  if (state.locked) {
    switch (action.type) {
      case StageGenerationUpdate.Generate: {
        if (action.complete) {
          return { ...state, locked: false }
        }
        return state
      }
      default: {
        console.warn('Attempted to perform action while state is locked - cancelling.', { action, state })
        return state
      }
    }
  }
  switch (action.type) {
    case StageGenerationUpdate.AssignStageReplacement: {
      const targetStage = state.stages.get(action.targetStage)
      if (!targetStage || targetStage?.isNew) {
        console.error('Unable to find target old stage for assign replacement in state - returning initial state', { action, state })
        return state
      }
      const replacementStage = action.replacementStage ? state.stages.get(action.replacementStage) : null
      const newAssignments = new Map(state.oldToNewAssignments)
      if (!action.replacementStage) {
        if (!newAssignments.get(action.targetStage)) {
          console.warn('Assign replacement stage action attempted to assign null to an already unassigned stage - returning initial state', { action, state })
          return state
        }
        newAssignments.set(action.targetStage, null)
      }
      if (action.replacementStage) {
        if (!replacementStage?.isNew) {
          console.error('Unable to find replacement new stage for assign replacement in state - returning initial state', {
            action,
            state
          })
          return state
        }
        const publishedAssessmentChanged = replacementStage.assessment.id !== targetStage.assessment.id
        const unpublishedAssessmentChanged = replacementStage.assessment.unpublishedAssessment?.id !== targetStage.assessment.unpublishedAssessment?.id
        const validReplacementOptions = getValidReplacementTypesForChanges({ publishedAssessmentChanged, unpublishedAssessmentChanged })
        const fallbackReplacementType = validReplacementOptions ? validReplacementOptions.shift() : null
        const replacementType = validateReplacementType(action.replacementType ?? fallbackReplacementType, { publishedAssessmentChanged, unpublishedAssessmentChanged })
        if (replacementType !== (action.replacementType ?? fallbackReplacementType)) {
          console.warn('Replacement type selected in action was not valid - replaced', { replacementType, action, state })
        }
        newAssignments.set(action.targetStage, {
          newId: replacementStage.id,
          oldId: action.targetStage,
          type: replacementType,
          oldPhaseId: targetStage.config.phaseId,
          newPhaseId: replacementStage.config.phaseId,
          phaseChange: targetStage.config.phaseId !== replacementStage.config.phaseId,
          index: replacementStage.index,
          oldIndex: targetStage.index,
          publishedAssessmentChanged: publishedAssessmentChanged,
          unpublishedAssessmentChanged: unpublishedAssessmentChanged
        })
      }
      return stageGenerationStateReducer({ ...state, oldToNewAssignments: newAssignments }, { type: StageGenerationUpdate.CheckResolved })
    }
    case StageGenerationUpdate.ChangeStageReplacementType: {
      const newAssignments = new Map(state.oldToNewAssignments)
      const oldAssignment = newAssignments.get(action.targetStage)
      if (!oldAssignment) {
        console.error('Unable to find replacement in state for assign replacement type - returning initial state', {
          action,
          state
        })
        return state
      }
      const publishedAssessmentChanged = oldAssignment.publishedAssessmentChanged
      const unpublishedAssessmentChanged = oldAssignment.unpublishedAssessmentChanged
      const replacementType = validateReplacementType(action.replacementType ?? null, { publishedAssessmentChanged, unpublishedAssessmentChanged })
      if (replacementType !== (action.replacementType ?? null)) {
        console.warn('Replacement type selected in assign type action was not valid - replaced', { replacementType, action, state })
      }
      newAssignments.set(action.targetStage, {
        ...oldAssignment,
        type: replacementType
      })
      return stageGenerationStateReducer({ ...state, oldToNewAssignments: newAssignments }, { type: StageGenerationUpdate.CheckResolved })
    }
    case StageGenerationUpdate.SetNewStageConfigProctored:
    case StageGenerationUpdate.SetNewStageConfigTimeLimit:
    case StageGenerationUpdate.SetNewStageConfigCollectDemographics: {
      const targetStage = state.stages.get(action.targetStage)
      if (!targetStage?.isNew) {
        console.error('Unable to find new target stage for config field update in state - returning initial state', { action, state })
        return state
      }
      const newStages = new Map(state.stages)
      const newConfig = setConfigFieldFromActionType(targetStage.config, action.type, action.value ?? null)
      if (Object.is(newConfig, targetStage.config)) {
        console.warn('Stage config did not change after action update - returning initial state', { newConfig, targetStage, action, state })
        return state
      }
      newStages.set(targetStage.id, { ...targetStage, config: newConfig })
      return { ...state, stages: newStages }
    }
    case StageGenerationUpdate.Generate:
    case StageGenerationUpdate.CheckResolved: {
      console.debug('Checking if state resolved', { action, state })
      const notifyIncomplete = (action.notifyIncomplete ?? false) || state.manualResolved
      if (state.warningsViewed && !state.resolvable) {
        console.debug('Not resolvable and warnings viewed - returning state.', { action, state })
        if (notifyIncomplete) {
          showNotification({
            title: 'Cannot Generate Stages',
            message: 'The specs for this cycle contain a module configuration which cannot be used to generate stages. Either there was an issue locating assessment data, or an error outlined in the warnings panel prevents stage generation.',
            color: 'red',
            autoClose: 10000
          })
        }
        return state
      }
      const isWarningView = action.isWarningView ?? false
      const autoCloseDuration = 5000
      let resolved = true
      let lastNewPhaseId = 0
      let lastNewIndex = -1
      let lastOldPhaseId = 0
      let lastOldIndex = -1
      for (const [id, reassignment] of state.oldToNewAssignments.entries()) {
        if (reassignment) {
          if (reassignment.type) {
            if (
              (reassignment.oldPhaseId < lastOldPhaseId) ||
              (reassignment.newPhaseId < lastNewPhaseId) ||
              (reassignment.oldIndex < lastOldIndex) ||
              (reassignment.index < lastNewIndex)
            ) {
              resolved = false
              if (notifyIncomplete) {
                showNotification({
                  title: 'Out of Order Reassignment',
                  message: `Old stage #${reassignment.oldIndex + 1} is assigned to a new stage which places it out of order with other reassignments.`,
                  color: 'red',
                  autoClose: autoCloseDuration
                })
              }
            } else if (state.stages.get(id).error) {
              showNotification({
                title: 'Unknown Error',
                message: `Old stage #${reassignment.oldIndex + 1} had an unrecoverable error when loading - reloading the page may help.`,
                color: 'red',
                autoClose: autoCloseDuration
              })
              resolved = false
            }
          } else {
            if (notifyIncomplete) {
              showNotification({
                title: 'Missing Reassignment Type',
                message: `Old stage #${reassignment.oldIndex + 1} must have a valid stage replacement type selected.`,
                color: 'yellow',
                autoClose: autoCloseDuration
              })
            }
            resolved = false
          }
          lastOldPhaseId = reassignment.oldPhaseId ?? lastOldPhaseId
          lastNewPhaseId = reassignment.newPhaseId ?? lastNewPhaseId
          lastOldIndex = reassignment.oldIndex ?? lastOldIndex
          lastNewIndex = reassignment.index ?? lastNewIndex
        } else {
          if (notifyIncomplete) {
            showNotification({
              title: 'Needs Reassignment',
              message: `Old stage #${state.stages.get(id).index + 1} must be reassigned.`,
              color: 'yellow',
              autoClose: autoCloseDuration
            })
          }
          resolved = false
        }
      }
      if (resolved && !(isWarningView || state.warningsViewed)) {
        if (notifyIncomplete) {
          showNotification({
            title: 'View Warnings',
            message: 'Carefully review stage warnings using the button at the top of the panel before generating stages!',
            color: 'yellow',
            autoClose: autoCloseDuration
          })
        }
        resolved = false
      }
      console.debug('State resolved check result', { resolved, action, state })
      return { ...state, manualResolved: resolved, warningsViewed: isWarningView ? true : state.warningsViewed, locked: ((action.complete === false) && resolved) ? true : state.locked }
    }
    default: {
      console.error('Unknown stage generation action - returning initial state', { action, state })
      return state
    }
  }
}

/**
 *
 * @param {null|int|undefined|StageReplacementType} replacementType
 * @param {object} changeMetadata { publishedAssessmentChanged: boolean, unpublishedAssessmentChanged: boolean }
 * @returns {StageReplacementType|int|null}
 */
function validateReplacementType (replacementType, changeMetadata) {
  if (!replacementType) {
    return null
  }

  const validReplacementTypes = getValidReplacementTypesForChanges(changeMetadata)
  if (!validReplacementTypes.includes(replacementType)) {
    return null
  }
  return replacementType
}

/**
 * @param {object} changeMetadata { publishedAssessmentChanged: boolean, unpublishedAssessmentChanged: boolean }
 * @returns {(int|StageReplacementType)[]} StageReplacementType[]
 */
export function getValidReplacementTypesForChanges (changeMetadata) {
  const { publishedAssessmentChanged, unpublishedAssessmentChanged } = (changeMetadata ?? {})
  if (!publishedAssessmentChanged) {
    return [StageReplacementType.ForwardApplicantResponsesToStageOnCompletion]
  }
  if (!unpublishedAssessmentChanged) {
    return [StageReplacementType.SendApplicantToFollowingStageOnCompletion, StageReplacementType.ForceRestartStageSeamlessly, StageReplacementType.ForceRestartStageImmediately]
  }
  return [StageReplacementType.SendApplicantToStageOnCompletion, StageReplacementType.ForceRestartStageSeamlessly, StageReplacementType.ForceRestartStageImmediately]
}

function setConfigFieldFromActionType (config, actionType, actionValue) {
  switch (actionType) {
    case StageGenerationUpdate.SetNewStageConfigProctored: {
      if (actionValue === config.proctored) {
        return config
      }
      return { ...config, proctored: actionValue }
    }
    case StageGenerationUpdate.SetNewStageConfigTimeLimit: {
      if (actionValue === config.timeLimit) {
        return config
      }
      return { ...config, timeLimit: actionValue }
    }
    case StageGenerationUpdate.SetNewStageConfigCollectDemographics: {
      if (actionValue === config.collectDemographics) {
        return config
      }
      return { ...config, collectDemographics: actionValue }
    }
    default: {
      console.error('Unknown config field update action target - returning original', { actionType, actionValue, config })
      return config
    }
  }
}
