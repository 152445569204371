import { useGetAssessmentInviteDetailQuery, useGetAssessmentInvitesForCycleQuery } from './AssessmentInvitesApi';
import { useSelector } from 'react-redux';
import { selectAllParamsQueryData } from '../../../../core/ReactTable/paramsSlice';
import { apiSlice } from '../../../../api';
import {
  BLANK_TABLE_ACTIONS,
  DEFAULT_HIDDEN_INVITE_COLUMNS,
  DEFAULT_INVITE_FILTERS,
  INVITE_COLUMNS,
  INVITE_FILTERS
} from '../CycleInviteHooks';
import { useCallback, useEffect, useRef } from 'react';

export function useAssessmentInviteDetail (assessmentInviteId, cycleId, skip = false) {
  const { data, isFetching: querying, isLoading: loading } = useGetAssessmentInviteDetailQuery({ assessmentInviteId, cycleId }, { skip })
  return [data ?? null, querying, loading]
}

export function useCyclesAssessmentInvites (namespace, cycleId, openInviteId = null, cyclePassId = null, skip = false) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = useGetAssessmentInvitesForCycleQuery({ ...queryParams, cycleId, openInviteId, cyclePassId }, { skip })
  return [collection ?? null, querying]
}

export function useLazyCyclesAssessmentInvites (namespace, cycleId, openInviteId = null, cyclePassId = null) {
  const queryParams = useSelector(state => selectAllParamsQueryData(state, namespace))
  const { data: collection, isFetching: querying } = apiSlice.endpoints.getAssessmentInvitesForCycle.useQueryState({ ...queryParams, cycleId, openInviteId, cyclePassId })
  return [collection ?? null, querying]
}

export function useAssessmentInvitesTable () {
  return {
    defaultHiddenColumns: DEFAULT_HIDDEN_INVITE_COLUMNS,
    defaultFilters: DEFAULT_INVITE_FILTERS,
    columns: INVITE_COLUMNS,
    filters: INVITE_FILTERS,
    actions: BLANK_TABLE_ACTIONS,
    searchable: true
  }
}

export function useConfirmNavigate (confirm = false) {
  const confirmNavigate = useRef(!!confirm)

  useEffect(() => {
    confirmNavigate.current = !!confirm
  }, [confirm])

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (confirmNavigate.current) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)

    return () => window.removeEventListener('beforeunload', onBeforeUnload)
  }, [])

  const setConfirmNavigate = useCallback((confirm) => {
    confirmNavigate.current = !!confirm
  }, [])

  return setConfirmNavigate
}
