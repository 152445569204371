import { ActionIcon, Anchor, Box, Button, Collapse, Divider, NavLink, Popover, ScrollArea } from '@mantine/core'
import { useClickOutside, useDisclosure } from '@mantine/hooks'
import React, { memo, useCallback, useMemo, useReducer, useRef, useState } from 'react'
import { IconCaretDownFilled, IconCaretUpFilled, IconChevronRight } from '@tabler/icons-react'
import { createInitialDropdownState, dropdownReducer, DropdownStateUpdate } from './NewDropdownState'
import PropTypes from 'prop-types'

const DEFAULTS = Object.freeze({
  width: 'auto',
  maxHeight: 350,
  openDelay: 300,
  closeDelay: 300
})

const itemStyle = {
  borderRadius: 4
}

export const NewDropdown = memo(function NewDropdown ({
  items = [],
  target,
  mah = DEFAULTS.maxHeight,
  width = DEFAULTS.width,
  openDelay = DEFAULTS.openDelay,
  closeDelay = DEFAULTS.closeDelay,
  row,
  children,
  disableHoverOpen = false,
  ...props
}) {
  const defaultOpenedItemKeys = useMemo(() => {
    return Object.entries(items).reduce((acc, [key, item]) => {
      if (item.defaultOpened) {
        acc.push(`item-${key}`)
      }

      return acc
    }
    , [])
  }, [items])

  const [state, dispatch] = useReducer(dropdownReducer, { openedMenus: defaultOpenedItemKeys, disableHoverOpen: disableHoverOpen }, createInitialDropdownState)
  const timerRef = useRef(null)
  const [targetClick, setTargetClick] = useState(null)
  const [dropdownClick, setDropdownClick] = useState(null)

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)

      timerRef.current = null
    }
  }

  const handleClickOutside = useCallback(() => {
    dispatch({ type: DropdownStateUpdate.Close })
  }, [dispatch])

  const handleMouseEnter = useCallback(() => {
    clearTimer()

    timerRef.current = setTimeout(() => {
      dispatch({ type: DropdownStateUpdate.MouseEnter })
    }, openDelay)
  }, [dispatch, openDelay]);

  const handleMouseLeave = useCallback(() => {
    clearTimer()

    timerRef.current = setTimeout(() => {
      dispatch({ type: DropdownStateUpdate.MouseLeave })
    }, closeDelay)
  }, [dispatch, closeDelay]);

  const clickOutsideRef = useClickOutside(handleClickOutside, ['mouseup', 'touchend'], [targetClick, dropdownClick])

  return (
    <Box ref={clickOutsideRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Popover
        opened={state.isOpen}
        width={width}
        onKeyDown={event => event.key === 'Escape' && dispatch({ type: DropdownStateUpdate.Close })}
        {...props}
      >
        <Popover.Target
          onClick={() => dispatch({ type: state.isFrozen ? DropdownStateUpdate.Unfreeze : DropdownStateUpdate.Open })}
          variant={state.isFrozen ? `active-${target.props?.variant || 'subtle'}` : target.props?.variant || 'subtle'}
          ref={setTargetClick}
          >
          {target}
        </Popover.Target>
        <Popover.Dropdown p={'xxs'} ref={setDropdownClick}>
          <>
          <ScrollArea.Autosize mah={mah} type='auto'>
          {Object.entries(items).map(([key, item]) => {
            const id = `item-${key}`
            return (
              <DropdownItem
                key={id}
                id={id}
                item={item}
                row={row}
                isOpen={state.openedMenus.includes(id)}
                dispatch={dispatch}
                />
            )
          })}
          </ScrollArea.Autosize>
          <CollapsibleDrawer>
            {children}
          </CollapsibleDrawer>
          </>
        </Popover.Dropdown>
      </Popover>
    </Box>
  )
})

const CollapsibleDrawer = memo(function CollapsibleDrawer ({ children }) {
  const [isCollapsed, { toggle }] = useDisclosure(true)
  const CollapsibleButton = useMemo(() => (
    <ActionIcon onClick={toggle} size='md'>
      {isCollapsed ? <IconCaretDownFilled /> : <IconCaretUpFilled />}
    </ActionIcon>
  ), [isCollapsed, toggle])

  return (
    <>
      <Divider label={CollapsibleButton} />
      <Collapse in={!isCollapsed}>
        <Box p='xs'>
          {children}
        </Box>
      </Collapse>
    </>
  )
})

const DropdownItem = memo(function DropdownItem ({ item, row, id }) {
  const [isOpen, setIsOpen] = useState(item.metadata?.defaultOpened || false)
  const itemProps = useMemo(() => {
    let _itemProps = { ...item }
    if (row) {
      if (item.metadata) {
        if (item.metadata.visible && item.metadata.visible(row) === false) {
          return null
        }
      }
      if (item.onClick) {
        _itemProps.onClick = () => item.onClick(row.id, row)
      }
      if (typeof item.href === 'function') {
        _itemProps.href = item.href(row.id)
      }
    }

    delete _itemProps.metadata

    if (item.metadata) {
      if (!item.href || item.component === Button) {
        _itemProps = {
          rightSection: item.metadata.items?.length > 0 && <IconChevronRight />,
          ..._itemProps
        }
      }
    }

    if (item.href) {
      _itemProps.component = Anchor
      _itemProps.variant = 'link-subtle'
    }

    return _itemProps
  }, [item, row])

  const subItems = item.metadata?.items ?? []

  return (
    <NavLink
      label={item.label}
      childrenOffset={0}
      opened={isOpen}
      onChange={setIsOpen}
      style={itemStyle}
      {...itemProps}
      active={isOpen}
      >
      {subItems.map((subItem) => (
        <DropdownSubItem key={`${id}-subitem-${formatLabelToId(subItem.label)}`} subItem={subItem} />
      ))}
    </NavLink>
  )
})

const DropdownSubItem = memo(function DropdownSubItem ({ subItem }) {
  const subItemProps = { ...subItem }
  delete subItemProps.metadata
  if (subItem.href) {
    subItemProps.component = Anchor

    if (!subItemProps.variant) {
      subItemProps.variant = 'link-subtle'
    }
  }

  return (
    <NavLink {...subItemProps} />
  )
})

function formatLabelToId (label) {
  return label.toLowerCase().replace(/ /g, '_')
}

NewDropdown.propTypes = {
  items: PropTypes.object,
  target: PropTypes.node,
  disableHoverOpen: PropTypes.bool,
  mah: PropTypes.number,
  width: PropTypes.string,
  openDelay: PropTypes.number,
  closeDelay: PropTypes.number,
  row: PropTypes.object,
  children: PropTypes.node
}

DropdownItem.propTypes = {
  item: PropTypes.object,
  row: PropTypes.object,
  id: PropTypes.string,
  dispatch: PropTypes.func,
  state: PropTypes.object
}

DropdownSubItem.propTypes = {
  subItem: PropTypes.object
}

CollapsibleDrawer.propTypes = {
  children: PropTypes.node
}
