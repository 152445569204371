import {
  Alert,
  ActionIcon,
  Anchor,
  Button,
  Checkbox,
  Chip,
  CloseButton,
  Combobox,
  Fieldset,
  Indicator,
  Input,
  List,
  Modal,
  MultiSelect,
  NavLink,
  NumberInput,
  Pagination,
  Paper,
  PillsInput,
  Select,
  Switch,
  TagsInput,
  UnstyledButton,
  Radio,
  Textarea,
  createTheme,
  darken,
  defaultVariantColorsResolver,
  parseThemeColor,
  rgba
} from '@mantine/core'
import { DateInput, DatePicker } from '@mantine/dates'
import { RichTextEditor } from '@mantine/tiptap'
import actionIconStyles from './modules/ActionIcon.module.scss'
import anchorStyles from './modules/Anchor.module.scss'
import buttonStyles from './modules/Button.module.scss'
import dateInputStyles from './modules/DateInput.module.scss'
import fieldsetStyles from './modules/Fieldset.module.scss'
import indicatorStyles from './modules/Indicator.module.scss'
import inputStyles from './modules/Input.module.scss'
import listStyles from './modules/List.module.scss'
import numberInputStyles from './modules/NumberInput.module.scss'
import paginationStyles from './modules/Pagination.module.scss'
import paperStyles from './modules/Paper.module.scss'
import richTextEditorStyles from './modules/RichTextEditor.module.scss'
import selectStyles from './modules/Select.module.scss'
import tagsInputStyles from './modules/TagsInput.module.scss'
import pillsInputStyles from './modules/PillsInput.module.scss'
import comboboxStyles from './modules/Combobox.module.scss'
import radioStyles from './modules/Radio.module.scss'
import textareaStyles from './modules/Textarea.module.scss'
import modalStyles from './modules/Modal.module.scss'
import alertStyles from './modules/Alert.module.scss'
import navLinkStyles from './modules/NavLink.module.scss'

export const theme = createTheme({
  spacing: {
    xxs: '0.425rem',
    xxxs: '0.125rem',
    xxl: '2.5rem'
  },
  colors: {
    blue: [
      '#F8FCFF',
      '#CFEDFF',
      '#A8DFFF',
      '#86D2FF',
      '#65C6FF',
      '#48BBFF',
      '#2DB1FF', // Hirescore Color
      '#008EE2',
      '#0076BB',
      '#006BAA'
    ],
    success: [
      '#E6FCF5',
      '#C3FAE8',
      '#96F2D7',
      '#63E6BE',
      '#38D9A9',
      '#20C997',
      '#12B886',
      '#0CA678',
      '#099268',
      '#087F5B'
    ]
  },
  components: {
    Button: Button.extend({ classNames: buttonStyles }),
    NavLink: NavLink.extend({ classNames: navLinkStyles, defaultProps: { color: 'dark', variant: 'subtle' } }),
    ActionIcon: ActionIcon.extend({ classNames: actionIconStyles, defaultProps: { color: 'gray', variant: 'subtle' } }),
    Input: Input.extend({ classNames: inputStyles }),
    CloseButton: CloseButton.extend({ classNames: buttonStyles }),
    UnstyledButton: UnstyledButton.extend({ classNames: buttonStyles }),
    Pagination: Pagination.extend({ classNames: paginationStyles }),
    TagsInput: TagsInput.extend({ classNames: tagsInputStyles }),
    DateInput: DateInput.extend({ classNames: dateInputStyles }),
    DatePicker: DatePicker.extend({ classNames: dateInputStyles }),
    Paper: Paper.extend({ classNames: paperStyles }),
    Modal: Modal.extend({
      classNames: modalStyles,
      styles: {
        content: { padding: 0 }
      }
    }),
    MultiSelect: MultiSelect.extend({ classNames: [tagsInputStyles, selectStyles] }),
    Select: Select.extend({ classNames: selectStyles }),
    Combobox: Combobox.extend({ classNames: comboboxStyles }),
    Checkbox: Checkbox.extend({ classNames: inputStyles }),
    Chip: Chip.extend({
      styles: {
        label: { display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center', alignContent: 'center' }
      }
    }),
    List: List.extend({ classNames: listStyles }),
    Fieldset: Fieldset.extend({ classNames: fieldsetStyles }),
    Anchor: Anchor.extend({ classNames: anchorStyles }),
    NumberInput: NumberInput.extend({ classNames: numberInputStyles }),
    RichTextEditor: RichTextEditor.extend({ classNames: richTextEditorStyles }),
    Indicator: Indicator.extend({ classNames: indicatorStyles }),
    Switch: Switch.extend({
      classNames: inputStyles,
      styles: {
        thumb: {
          top: '0.125rem'
        }
      }
    }),
    PillsInput: PillsInput.extend({ classNames: pillsInputStyles }),
    Radio: Radio.extend({
      classNames: radioStyles,
      vars: (theme, props) => {
        if (props.size === 'xl') {
          return {
            root: {
              '--radio-icon-size': 'var(--radio-icon-size-xl)'
            }
          }
        }
        if (props.size === 'lg') {
          return {
            root: {
              '--radio-icon-size': 'var(--radio-icon-size-lg)'
            }
          }
        }
        if (props.size === 'md') {
          return {
            root: {
              '--radio-icon-size': 'var(--radio-icon-size-md)'
            }
          }
        }
        if (props.size === 'sm') {
          return {
            root: {
              '--radio-icon-size': 'var(--radio-icon-size-sm)'
            }
          }
        }
        if (props.size === 'xs') {
          return {
            root: {
              '--radio-icon-size': 'var(--radio-icon-size-xs)'
            }
          }
        }
        return { root: {} }
      }
    }),
    Alert: Alert.extend({
      classNames: alertStyles
    }),
    Textarea: Textarea.extend({
      classNames: textareaStyles
    })
  },
  variantColorResolver: (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input);
    const parsedColor = parseThemeColor({
      color: input.color || input.theme.primaryColor,
      theme: input.theme
    });

    if (input.variant === 'light') {
      return {
        ...defaultResolvedColors,
        hover: rgba(parsedColor.value, 0.2),
        hoverColor: `var(--mantine-color-${parsedColor.value}-light-color)`
      }
    }

    if (input.variant === 'danger-subtle') {
      return {
        background: 'transparent',
        color: parsedColor.value,
        hover: 'var(--mantine-color-red-light-hover)'
      }
    }

    if (input.variant === 'active-light') {
      return {
        background: rgba(parsedColor.value, 0.2),
        border: `1px solid ${rgba(parsedColor.value, 0.5)}`,
        color: parsedColor.value,
        hover: rgba(parsedColor.value, 0.3)
      }
    }

    if (input.variant === 'active-outline') {
      return {
        background: rgba(parsedColor.value, 0.3),
        border: `1px solid ${parsedColor.value}`,
        color: parsedColor.value,
        hover: rgba(parsedColor.value, 0.2)
      }
    }

    if (input.variant === 'active-subtle') {
      return {
        background: rgba(parsedColor.value, 0.1),
        border: `1px solid ${rgba(parsedColor.value, 0.5)}`,
        color: parsedColor.value,
        hover: rgba(parsedColor.value, 0.2)
      };
    }

    if (input.variant === 'active-filled') {
      return {
        background: darken(parsedColor.value, 0.2),
        hover: darken(parsedColor.value, 0.3),
        border: `1px solid ${darken(parsedColor.value, 0.2)}`
      }
    }

    if (input.variant === 'link-subtle') {
      return {
        background: 'transparent',
        color: parsedColor.value,
        hover: 'var(--mantine-color-blue-light-hover)'
      }
    }

    return defaultResolvedColors
  }
})
