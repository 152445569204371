import { createSelector, createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'selectedColumns',
  initialState: {},
  reducers: {
    selectOne: (state, action) => {
      const { key, value } = action.payload
      state[key] = value
    },
    unselectOne: (state, action) => {
      const { key } = action.payload
      delete state[key]
    },
    selectBulk: (state, action) => {
      const { rows, keyAccessor, valueAccessor } = action.payload
      rows.forEach(row => {
        state[row[keyAccessor]] = valueAccessor
          ? row[valueAccessor]
          : row
      })
    },
    unselectBulk: (state, action) => {
      const { keys } = action.payload
      keys.forEach((key) => delete state[key])
    },
    unselectAll: () => {
      return {}
    }
  }
})

const selectedColumns = (state) => state.selectedColumns

export const selectAllSelectedKeys = createSelector(
  [selectedColumns],
  (selected) => Object.keys(selected)
)

export const selectAllSelectedValues = createSelector(
  [selectedColumns],
  (selected) => Object.values(selected)
)

export const { selectOne, unselectOne, selectBulk, unselectBulk, unselectAll } = counterSlice.actions

export default counterSlice.reducer
